import SharedItemsList from './shared-items/SharedItemsList.vue';
import NumberSelected from './components/NumberSelected.vue';
import FailureReasons from './components/errors/FailureReasons.vue';
import InstallModal from './install/components/InstallModal.vue';
import SingleAppInstallModal from './install/components/SingleAppInstallModal.vue';
import InstallRequest from './install/components/request/InstallRequest.vue';
import BatchInstallRequestModal from './install/components/request/batch/BatchInstallRequestModal.vue';
import FailureReason from './components/errors/FailureReason.vue';
import FailureInlineAlert from './components/errors/FailureInlineAlert.vue';
import TypeLabel from './components/TypeLabel.vue';
import { useError } from './components/errors/useError';
import contentSharingLibMessages from './i18n/en-us.json';
import ShareItemProgress from './components/ShareItemProgress.vue';
import ImageWrapper from './components/ImageWrapper.vue';
import CopyText from './components/CopyText.vue';
import { useShareUrlsStore } from './stores/shareUrls';
import { useInstallRequestStore } from './stores/request';
import { ACTIVE_UI } from './constants/share.links.constants';

export function createLibrary(options = {}) {
    const library = {
        install() {
            const shareUrls = useShareUrlsStore();

            shareUrls.setPackagingServiceUrl(options.packagingServiceUrl);
            shareUrls.setWebUrl(options.webUrl);
            shareUrls.setUltimateUrl(options.ultimateUrl);
            shareUrls.setClassicUrl(options.classicUrl);
        },
    };

    return library;
}

export * from './constants/share.constants';
export * from './share.util';

export {
    contentSharingLibMessages,
    SharedItemsList,
    NumberSelected,
    FailureReasons,
    FailureReason,
    FailureInlineAlert,
    TypeLabel,
    useError,
    useInstallRequestStore,
    InstallModal,
    SingleAppInstallModal,
    BatchInstallRequestModal,
    ShareItemProgress,
    ImageWrapper,
    InstallRequest,
    CopyText,
    ACTIVE_UI,
};

