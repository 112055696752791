export const FF_GENERATE_CART_LINK = 'keap.sharing.new.cart.links';
export const FF_RESHARING = 'keap.sharing.internal.resharing';
export const FF_BULK_CREATE_BUNDLE = 'keap.sharing.create.bundle.bulk.action';
export const FF_SLP_INSTALL_HISTORY = 'keap.sharing.install.history.page';
export const FF_TYPE_SEARCH = 'keap.sharing.resource.type.search';
export const FF_BATCH_INSTALL = 'keap.sharing.batch.install';
export const FF_SLP_PERMISSIONS = 'keap.sharing.user.permissions';

// Shareable resource types
export const FF_SHARING_SAVED_SEARCH_CONTACT = 'keap.sharing.type.savedSearch.contact';
export const FF_SHARING_SAVED_SEARCH_COMPANY = 'keap.sharing.type.savedSearch.company';
export const FF_SHARING_SAVED_SEARCH_ORDER = 'keap.sharing.type.savedSearch.order';
export const FF_SHARING_SAVED_SEARCH_REFERRAL_PARTNER = 'keap.sharing.type.savedSearch.referralPartner';
export const FF_SHARING_SAVED_SEARCH_SUBSCRIPTION = 'keap.sharing.type.savedSearch.subscription';
export const FF_SHARING_SAVED_SEARCH_VISITOR = 'keap.sharing.type.savedSearch.visitor';
export const FF_SHARING_TEXT_TEMPLATE_EMAIL = 'keap.sharing.type.textTemplate.email';
export const FF_SHARING_TEXT_TEMPLATE_SMS_BROADCAST = 'keap.sharing.type.textTemplate.smsBroadcast';
export const FF_SHARING_TEXT_TEMPLATE_SMS_AUTOMATION = 'keap.sharing.type.textTemplate.smsAutomation';
export const FF_SHARING_TEXT_TEMPLATE_SMS_CUSTOM = 'keap.sharing.type.textTemplate.smsCustom';
export const FF_SHARING_AFFILIATE_CUSTOM_FIELDS = 'keap.sharing.type.affiliateCustomField';
export const FF_SHARING_ROLES = 'keap.sharing.type.role';
export const FF_SHARING_LANDING_PAGE_POPUP = 'keap.sharing.type.popup';
