import { FAILURE_REASON } from './share.constants';

export const supportedEnhancedErrorTypes = [
    FAILURE_REASON.PROVIDER_FAILURE,
    FAILURE_REASON.CONFLICTING_TRACKED_RESOURCES,
    FAILURE_REASON.CIRCULAR_DEPENDENCY,
    FAILURE_REASON.DEPENDENCY_NOT_FOUND,
    FAILURE_REASON.ILLEGAL_DEPENDENCY,
    FAILURE_REASON.SHARED_RESOURCE_NOT_FOUND,
    FAILURE_REASON.BUNDLE_EMPTY,
    FAILURE_REASON.RESHARING_FORBIDDEN,
    FAILURE_REASON.SOURCE_RESOURCE_NOT_FOUND,
    FAILURE_REASON.UNKNOWN_FAILURE,
];
