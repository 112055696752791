<template>
    <i18n-t :keypath="reason" scope="global" tag="div">
        <template #itemOrBundle>
            {{ isBundle ? t('ContentSharingLib.errors.bundle') : t('ContentSharingLib.errors.item') }}
        </template>

        <template #link>
            <DsLink :href="supportLink">
                {{ t('ContentSharingLib.errors.install.validation.failed.link') }}
            </DsLink>
        </template>

        <template #learnMore>
            <DsLink :href="learnMoreLink">
                {{ t('ContentSharingLib.global.learnMore') }}
            </DsLink>
        </template>
    </i18n-t>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { FAILURE_REASON } from '../../../constants/share.constants';

export default {
    props: {
        errorCode: {
            type: String,
            default: '',
        },
        isBundle: {
            type: Boolean,
            default: false,
        },
        isSlpPermissionsEnabled: Boolean,
    },

    setup() {
        return {
            t: useI18n().t,
            supportLink: 'https://help.keap.com/help/contact-support',
            learnMoreLink: 'https://help.keap.com/help/roles-and-permissions#managing-permissions',
        };
    },

    computed: {
        reason() {
            switch (this.errorCode) {
            case FAILURE_REASON.USER_IS_NOT_ADMIN_ON_TARGET_TENANT: return this.isSlpPermissionsEnabled ? 'ContentSharingLib.errors.reason.userHasNoPermission.consumer' : 'ContentSharingLib.errors.reason.userIsNotAdmin.consumer';
            case FAILURE_REASON.SOURCE_TENANT_MATCHES_DESTINATION: return 'ContentSharingLib.errors.reason.sourceTenantMatchesDestination.consumer';
            case FAILURE_REASON.LICENSE_LIMIT_REACHED: return 'ContentSharingLib.errors.reason.limitReached.consumer';
            case FAILURE_REASON.LICENCE_INVALID: return 'ContentSharingLib.errors.reason.licenceInvalid.consumer';
            default: return 'ContentSharingLib.errors.install.validation.failed';
            }
        },
    },
};
</script>
