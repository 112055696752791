import { REQUEST_STATUSES, BATCH_INSTALL_STATUSES } from './share.constants';

const {
    QUEUED, IN_PROGRESS, NEEDS_RETRIED, BUILDING_DEPENDENCIES, LOADING_DEPENDENCIES, EXPORTING, INSTALLING, FAILED, COMPLETED, VALIDATING, UNINSTALLING, UNINSTALLED,
} = REQUEST_STATUSES;

export default {
    [QUEUED]: { min: 0, max: 10, label: 'ContentSharingLib.progress.label.queued' },
    [IN_PROGRESS]: { min: 10, max: 30, label: 'ContentSharingLib.progress.label.inProgress' },
    [NEEDS_RETRIED]: { min: 10, max: 15, label: 'ContentSharingLib.progress.label.needsRetried' },
    [BUILDING_DEPENDENCIES]: { min: 10, max: 50, label: 'ContentSharingLib.progress.label.buildingDependencies' },
    [LOADING_DEPENDENCIES]: { min: 10, max: 60, label: 'ContentSharingLib.progress.label.loadingDependencies' },
    [EXPORTING]: { min: 30, max: 97, label: 'ContentSharingLib.progress.label.exporting' },
    [INSTALLING]: { min: 30, max: 97, label: 'ContentSharingLib.progress.label.installing' },
    [UNINSTALLING]: { min: 0, max: 97, label: 'ContentSharingLib.progress.label.uninstalling' },
    [FAILED]: { min: 100, max: 100, label: 'ContentSharingLib.progress.label.failed' },
    [COMPLETED]: { min: 100, max: 100, label: 'ContentSharingLib.progress.label.completed' },
    [UNINSTALLED]: { min: 100, max: 100, label: 'ContentSharingLib.progress.label.uninstalled' },
    [VALIDATING]: { label: 'ContentSharingLib.progress.label.validating' },
    [BATCH_INSTALL_STATUSES.COMPLETED]: { label: 'ContentSharingLib.progress.label.completed' },
    [BATCH_INSTALL_STATUSES.INSTALLING]: { label: 'ContentSharingLib.progress.label.installing' },
};
