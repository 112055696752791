import { defineStore } from 'pinia';
import { useSentry } from '@keap-web/analytics-lib/sentry';
import {
    loadAccounts, loadPartnerGroups, createPartnerGroup, addUserToPartnerGroup, loadUserPermissions,
} from '@/accounts/api';
import {
    LOADING, SUCCESS, ERROR, IDLE,
} from '@/constants/loadingStatuses.constants';
import { useAuthStore } from '@/stores/auth';

export const useAccountsStore = defineStore('accounts', {
    state: () => ({
        appId: '',
        apps: [],
        allAppsMap: {},
        sandboxes: [],
        loadAccountsStatus: IDLE,
        partnerGroup: {},
        sentry: useSentry(),
        userPermissions: [],
    }),

    getters: {
        getCompanyName: ({ allAppsMap }) => (tenantId) => {
            return allAppsMap[tenantId]?.companyName || '';
        },

        hasPermission: ({ userPermissions }) => (permission) => {
            return userPermissions.includes(permission);
        },
    },

    actions: {
        updateAppId(appId) {
            this.appId = appId;
        },

        setApps(accounts) {
            this.apps = accounts;
        },

        setAllApps(accounts) {
            accounts.forEach((account) => {
                this.allAppsMap[account.tenantId] = account;
            });
        },

        setSandboxes(accounts) {
            this.sandboxes = accounts;
        },

        setPartnerGroup(group) {
            this.partnerGroup = group;
        },

        setUserPermissions(permissions) {
            this.userPermissions = permissions;
        },

        loadAccountsStart() {
            this.loadAccountsStatus = LOADING;
        },

        loadAccountsError() {
            this.loadAccountsStatus = ERROR;
        },

        loadAccountsSuccess() {
            this.loadAccountsStatus = SUCCESS;
        },

        async loadAccounts(params) {
            try {
                this.loadAccountsStart();
                const authStore = useAuthStore();
                const accounts = await loadAccounts(authStore.user?.id, params);

                this.setApps(accounts);
                this.loadAccountsSuccess();
            } catch (e) {
                this.sentry.captureException(e, {
                    message: 'Load accounts failed',
                });

                this.loadAccountsError();
            }
        },

        async loadAllAccounts() {
            if (Object.keys(this.allAppsMap).length > 0) {
                return;
            }

            try {
                const authStore = useAuthStore();
                const accounts = await loadAccounts(authStore.user.id, { pageSize: 500 });

                this.setAllApps(accounts);
            } catch (e) {
                this.sentry.captureException(e, {
                    message: 'Load all accounts failed',
                });
            }
        },

        async loadSandboxes() {
            if (!this.sandboxes.length) {
                const { apps, appId } = this;
                const authStore = useAuthStore();
                const userId = authStore.user.id;
                const sandboxes = await loadAccounts(userId, { pageSize: 100, sandboxOnly: true });

                // if sandboxes does not contain logged in appId, add it
                if (!sandboxes.find(({ tenantId }) => tenantId === appId)) {
                    let currentApp = apps.find(({ tenantId }) => tenantId === appId);

                    if (!currentApp) {
                        const appList = await loadAccounts(userId, { pageSize: 1, searchText: appId });

                        [currentApp] = appList;
                    }

                    sandboxes.unshift({ tenantId: appId, companyName: currentApp.companyName });
                }

                this.setSandboxes(sandboxes);
            }
        },

        async loadPartnerGroups({ tenantId, userId }) {
            const { userGroups } = await loadPartnerGroups({ userId, tenantId });

            if (userGroups.length === 0) {
                const partnerGroup = await createPartnerGroup({ tenantId, title: 'Default group' });

                await addUserToPartnerGroup({ partnerGroupId: partnerGroup.id, userId });

                userGroups.push({ group: partnerGroup });
            }

            this.setPartnerGroup(userGroups[0].group);
        },

        async loadPermissions({ tenantId, userId }) {
            try {
                const permissions = await loadUserPermissions(userId, tenantId);

                this.setUserPermissions(permissions);
            } catch (e) {
                this.sentry.captureException(e, {
                    message: `Load user permissions failed for userId: ${userId} and tenantId: ${tenantId}`,
                });
            }
        },
    },

    persist: {
        paths: ['appId'],
    },
});
