import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useInstallQueries } from '../../install/api/install';
import { REQUEST_STATUSES } from '../../constants/share.constants';

export const useInstallRequestStore = defineStore('installRequest', () => {
    const dependencyList = ref([]);
    const uninstalledDependencyList = ref([]);
    const batchFailedTenantIds = ref([]);
    const batchInstall = ref({});
    const batchRequestId = ref(null);
    const installItem = ref({});
    const partnerInfo = ref({});
    const queries = useInstallQueries();

    function reset() {
        dependencyList.value = [];
        uninstalledDependencyList.value = [];
        batchFailedTenantIds.value = [];
        batchInstall.value = {};
        batchRequestId.value = null;
        installItem.value = {};
        partnerInfo.value = {};
    }

    function resetBatchRequestId() {
        batchRequestId.value = null;
    }

    async function fetchFromLink(linkId) {
        const { data: { linkInfo } } = await queries.getLinkInfo(linkId);

        dependencyList.value = linkInfo.dependencies || [];
        partnerInfo.value = linkInfo.company;
        installItem.value = {
            ...linkInfo,
            company: undefined,
        };
    }

    async function fetchFromRequest(requestId) {
        const { data: { installRequest } } = await queries.getRequestInfo(requestId);

        partnerInfo.value = installRequest.company;
        dependencyList.value = installRequest.dependencies || [];
        installItem.value = {
            ...installRequest,
            company: undefined,
            dependencies: undefined,
        };
    }

    async function fetchFromBatchRequest(batchInstallRequestId, params) {
        const { data: { batchInstallRequest } } = await queries.getBatchRequestInfo({ batchInstallRequestId, ...params });

        batchInstall.value = batchInstallRequest;
    }

    async function uninstall(requestId) {
        const { data: { uninstallRequest } } = await queries.uninstall(requestId);

        uninstalledDependencyList.value = uninstallRequest.dependencies || [];

        if (uninstalledDependencyList.value.some(({ uninstallSuccess }) => uninstallSuccess)) {
            installItem.value.status = REQUEST_STATUSES.UNINSTALLED;
            installItem.value.updateTime = new Date().toISOString();
        }
    }

    async function createBatchInstallRequest({ tenantIds, sharedResourceId }) {
        const dedupeTenantIds = [...new Set(tenantIds)];

        const { data: { createBatchInstallRequest: response } } = await queries.createBatchInstallRequest({ tenantIds: dedupeTenantIds, sharedResourceId });

        batchFailedTenantIds.value = response?.failedTenantIds;
        batchRequestId.value = response?.batchRequestId;
    }

    async function getBatchInstallsList(params) {
        const { data: { batchInstallRequestList: response } } = await queries.getBatchInstallsList(params);

        return {
            ...response,
            batchRequests: response.batchRequests?.map(({ sharedResource, ...rest }) => ({
                ...rest,
                ...sharedResource,
            })) || [],
        };
    }

    async function getBatchInstallSummary(requestId) {
        const { data: { batchInstallSummary } } = await queries.getBatchInstallSummary(requestId);

        return batchInstallSummary;
    }

    return {
        dependencyList,
        uninstalledDependencyList,
        installItem,
        partnerInfo,
        batchFailedTenantIds,
        batchInstall,
        batchRequestId,

        reset,
        resetBatchRequestId,
        fetchFromLink,
        fetchFromRequest,
        fetchFromBatchRequest,
        uninstall,
        createBatchInstallRequest,
        getBatchInstallsList,
        getBatchInstallSummary,
    };
});
