<template>
    <main v-if="$route.meta.isPublic">
        <section data-qa="public-content" class="content">
            <RouterView />
        </section>
    </main>

    <main v-else-if="$route.name" class="main">
        <div v-if="isLoading" class="loading-spinner">
            <DsSpinner />
        </div>

        <template v-else>
            <BootLoadError v-if="bootLoadError" data-qa="boot-error" />

            <UnauthorizedError v-else-if="showUnauthorizedError" data-qa="unauthorized-error" />

            <ShareAppFtu v-else-if="showFtu && !$route.meta.skipAuthorization" data-qa="share-ftu" />

            <template v-else-if="showRouterView">
                <NavLogo v-if="$route.meta.showIcon" class="nav-logo" data-qa="nav-logo" />

                <section data-qa="content" class="content">
                    <RouterView />
                </section>
            </template>

            <AnalyticsHandler />
        </template>
    </main>

    <DsToastContainer />
</template>

<script>
import { computed } from 'vue';
import {
    ERROR, SUCCESS, IDLE, LOADING,
} from '@/constants/loadingStatuses.constants';

import BootLoadError from '@/components/BootLoadError.vue';
import UnauthorizedError from '@/components/UnauthorizedError.vue';
import ShareAppFtu from '@/components/ShareAppFtu.vue';
import NavLogo from '@/components/NavLogo.vue';
import AnalyticsHandler from '@/analytics/components/AnalyticsHandler.vue';
import { useAuthStore } from '@/stores/auth';
import { useAccountsStore } from '@/accounts/store';
import { useTutorialStore } from '@/stores/tutorials';
import { useGlobalStore } from '@/stores/global';
import { usePendo } from '@keap-web/analytics-lib/pendo';

export default {
    components: {
        BootLoadError,
        UnauthorizedError,
        ShareAppFtu,
        NavLogo,
        AnalyticsHandler,
    },

    setup() {
        const authStore = useAuthStore();
        const tutorialStore = useTutorialStore();
        const globalStore = useGlobalStore();
        const accountsStore = useAccountsStore();
        const isFtuDismissed = computed(() => tutorialStore.items.SHARE_APP_FTU);
        const authStatus = computed(() => authStore.status);
        const isLoggedIn = computed(() => authStore.isLoggedIn);
        const bootStatus = computed(() => globalStore.boot.status);
        const isAuthorized = computed(() => globalStore.boot.isAuthorized);
        const overlaysActive = computed(() => globalStore.boot.overlaysActive);
        const appId = computed(() => accountsStore.appId);

        usePendo().attachScripts();

        return {
            authStatus,
            isLoggedIn,
            isFtuDismissed,
            bootStatus,
            isAuthorized,
            overlaysActive,
            appId,
            isTermsAccepted: computed(() => authStore.termsAccepted),
        };
    },

    computed: {
        isLoading() {
            return [IDLE, LOADING].includes(this.bootStatus) || [IDLE, LOADING].includes(this.authStatus);
        },

        bootLoadError() {
            return this.authStatus === ERROR || this.bootStatus === ERROR;
        },

        showRouterView() {
            return this.isLoggedIn && this.bootStatus === SUCCESS;
        },

        showFtu() {
            const showFtu = !this.isFtuDismissed;
            const showTerms = !this.isTermsAccepted;

            return this.showRouterView && (showFtu || showTerms);
        },

        showUnauthorizedError() {
            return !this.isAuthorized && !this.$route.meta.skipAuthorization;
        },
    },

    watch: {
        overlaysActive(overlaysActive) {
            if (overlaysActive > 0) {
                document.body.classList.add('overlay-active');
            } else {
                document.body.classList.remove('overlay-active');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main {
    display: flex;
    justify-content: center;
}

.content {
    height: 100vh;
    flex: 1;
}

.nav-logo {
    margin-top: $spacing-150;
    @include margin-end($spacing-200);
}

@media ($small) {
    .nav-logo {
        display: none;
    }

    .content {
        width: 100vw;
    }
}
</style>
