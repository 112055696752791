<template>
    <DsInlineAlert
        class="error-alert"
        leading-icon
        type="critical"
    >
        <template #default>
            <ConsumerFailureReason
                :is-slp-permissions-enabled="isSlpPermissionsEnabled"
                :is-bundle="isBundle"
                :error-code="supportedFailures[0]"
            />
        </template>
    </DsInlineAlert>
</template>

<script>
import ConsumerFailureReason from './ConsumerFailureReason.vue';
import { FAILURE_REASON } from '../../../constants/share.constants';

export const CONSUMER_SUPPORTED_FAILURE_REASONS = [
    FAILURE_REASON.LICENCE_INVALID,
    FAILURE_REASON.LICENSE_LIMIT_REACHED,
    FAILURE_REASON.SOURCE_TENANT_MATCHES_DESTINATION,
    FAILURE_REASON.USER_IS_NOT_ADMIN_ON_TARGET_TENANT,
];

export default {
    components: {
        ConsumerFailureReason,
    },

    props: {
        errors: {
            type: Array,
            default: () => [''],
        },
        isBundle: {
            type: Boolean,
            default: false,
        },
        isSlpPermissionsEnabled: Boolean,
    },

    setup() {
        return {
            supportLink: 'https://help.keap.com/help/contact-support',
        };
    },

    computed: {
        normalizedErrors() {
            return this.errors.map((error) => {
                return error.failureReason || error;
            });
        },

        supportedFailures() {
            return this.normalizedErrors.filter((error) => CONSUMER_SUPPORTED_FAILURE_REASONS.includes(error));
        },
    },
};
</script>

<style lang="scss" scoped>
.error-alert {
    width: 100%;
}
</style>
