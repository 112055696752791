import { i18n } from '@/i18n';
import { ERROR } from '@/constants/loadingStatuses.constants';
import { useAuthStore } from '@/stores/auth';
import { useAccountsStore } from '@/accounts/store';
import { useGlobalStore } from '@/stores/global';

const setTitle = (route) => {
    const baseTitle = i18n.global.t(route.meta.title || 'global.title', { productName: i18n.global.t('global.productName') });

    setTimeout(() => {
        document.title = baseTitle;
    });
};

const routeHasRequiredFeatureFlag = (route) => {
    const globalStore = useGlobalStore();
    const { featureFlags } = globalStore;
    const { featureFlag } = route.meta;

    return Boolean(!featureFlag || (featureFlags && featureFlags[featureFlag]));
};

const routeIsValid = (to) => {
    return to.matched.every((route) => {
        return routeHasRequiredFeatureFlag(route);
    });
};

export const beforeResolveRoute = async (to, _, next) => {
    const authStore = useAuthStore();
    const accountsStore = useAccountsStore();
    let appId = to.query.app_id || to.params.appId;

    if (appId) {
        accountsStore.updateAppId(appId);
    } else if (window.__KEAP__.VUE_APP_APP_ID) {
        accountsStore.updateAppId(window.__KEAP__.VUE_APP_APP_ID);
    }

    if (to.query.ticket) {
        // Remove ticket and redirect parameters from request uri.
        const { query: { ticket, redirect, ...query }, ...nextRoute } = to;
        let jwt;

        if (Array.isArray(ticket)) { // in case of multiple, take the last one
            jwt = ticket[ticket.length - 1];
        } else {
            jwt = ticket;
        }

        await authStore.login(jwt);

        nextRoute.query = query;
        nextRoute.replace = true;

        if (authStore.status === ERROR) {
            // just let the oops page be shown
            return next();
        }

        return next(nextRoute);
    }

    await authStore.verifyLogin();

    const { isLoggedIn } = authStore;

    if (!isLoggedIn && !to.meta.isPublic) {
        appId = appId || accountsStore.appId;

        const redirectUrl = new URL(window.location.href);

        if (appId && !redirectUrl.searchParams.has('app_id')) {
            redirectUrl.searchParams.append('app_id', appId);
        }

        window.location.assign(`${window.__KEAP__.VUE_APP_CAS_URL}/login?service=${encodeURIComponent(redirectUrl.toString())}`);

        return next(false);
    }

    const globalStore = useGlobalStore();

    if (isLoggedIn && !globalStore.boot.isAuthorized) {
        await globalStore.loadBoot();
    }

    if (!routeIsValid(to)) {
        return next('/');
    }

    setTitle(to);

    return next();
};
