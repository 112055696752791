import { defineStore } from 'pinia';
import { SHARE_ITEM_STATUSES } from '@/share/share.constants';
import { REQUEST_STATUSES, FAILURE_REASON, RESOURCE_TYPES } from '@keap-web/content-sharing-lib';
import * as api from '@/share/api';
import { getFailureReasons } from '@/share/share.util';
import * as linksApi from '@/share/api/links';
import { useAccountsStore } from '@/accounts/store';
import { ref, computed } from 'vue';

const {
    IDLE, STAGED, LOADING, SUCCESS, ERROR, COMPLETE,
} = SHARE_ITEM_STATUSES;

export const useShareStore = defineStore('share', () => {
    const shareItems = ref([]);
    const exportItem = ref({});
    const sourceSandboxId = ref('');
    const partnerInfo = ref({});
    const dismissedExportWarnings = ref(false);

    const isSharingLoading = computed(() => {
        return shareItems.value.some(({ status }) => status === LOADING);
    });
    const isSharingComplete = computed(() => {
        const items = shareItems.value.filter(({ status }) => status !== IDLE);

        return Boolean(items.length && items.every(({ status }) => [SUCCESS, COMPLETE].includes(status)));
    });
    const isSharingFailed = computed(() => {
        return shareItems.value.some(({ status }) => status === ERROR);
    });
    const isSharingFinished = computed(() => {
        const completeItems = shareItems.value.filter(({ status }) => [COMPLETE].includes(status));
        const hasWaitingItems = shareItems.value.some(({ status }) => [STAGED, LOADING].includes(status));

        return completeItems.length > 0 && !hasWaitingItems;
    });
    const sharedItems = computed(() => {
        return shareItems.value.filter(({ status }) => [SUCCESS, COMPLETE, STAGED, LOADING, ERROR].includes(status));
    });
    const isExportLoading = computed(() => {
        return [REQUEST_STATUSES.QUEUED,
            REQUEST_STATUSES.VALIDATING,
            REQUEST_STATUSES.IN_PROGRESS,
            REQUEST_STATUSES.EXPORTING,
            REQUEST_STATUSES.BUILDING_DEPENDENCIES,
            REQUEST_STATUSES.LOADING_DEPENDENCIES,
            REQUEST_STATUSES.NEEDS_RETRIED].includes(exportItem.value.status);
    });
    const isExportFailed = computed(() => {
        return [REQUEST_STATUSES.FAILED].includes(exportItem.value.status);
    });
    const hasExportWarnings = computed(() => {
        const { warningReasons } = exportItem.value;

        return Boolean(!dismissedExportWarnings.value && warningReasons?.length);
    });
    const sourceSandbox = computed(() => {
        return useAccountsStore().sandboxes.find(({ tenantId }) => tenantId === sourceSandboxId.value);
    });

    function reset() {
        shareItems.value = [];
        exportItem.value = {};
        partnerInfo.value = {};
        dismissedExportWarnings.value = false;
    }

    function dismissExportWarnings() {
        dismissedExportWarnings.value = true;
    }

    function resetDismissExportWarnings() {
        dismissedExportWarnings.value = false;
    }

    function setSourceSandboxId(appId) {
        sourceSandboxId.value = appId;
    }

    function updateExportItem(item) {
        exportItem.value = item;
    }

    function resetExportItemStatus() {
        exportItem.value.status = '';
        exportItem.value.failureReasons = [];
        exportItem.value.warningReasons = [];
        exportItem.value.missingRequiredFeatures = [];
        dismissedExportWarnings.value = false;
    }

    function addShareItem(item) {
        shareItems.value.push(item);
    }

    function removeShareItem(item) {
        const updateIndex = shareItems.value.findIndex(({
            resourceId, sharedResourceId, sourceTenantId, resourceSubtype, resourceType,
        }) => {
            if (sharedResourceId && item.sharedResourceId) {
                return item.sharedResourceId === sharedResourceId;
            }

            return item.resourceId === resourceId
                && item.sourceTenantId === sourceTenantId
                && item.resourceSubtype === resourceSubtype
                && item.resourceType === resourceType;
        });

        if (updateIndex > -1) {
            shareItems.value.splice(updateIndex, 1);
        }
    }

    function removeShareItemByResourceId(resourceId) {
        const updateIndex = shareItems.value.findIndex((item) => item.resourceId === resourceId || item.sourceResourceId === resourceId);

        if (updateIndex > -1) {
            shareItems.value.splice(updateIndex, 1);
        }
    }

    function updateShareItem(item) {
        const updateIndex = shareItems.value.findIndex(({ resourceId, sourceTenantId, resourceType }) => {
            return item.resourceId === resourceId
                    && item.sourceTenantId === sourceTenantId
                    && item.resourceType === resourceType;
        });

        if (updateIndex > -1) {
            shareItems.value.splice(updateIndex, 1, item);
        }
    }

    function setPartnerInfo(info = {}) {
        partnerInfo.value = info;
    }

    function markShareItemsAsShared() {
        shareItems.value = shareItems.value.map((item) => ({ ...item, status: SUCCESS }));
    }

    function markShareItemsAsStaged() {
        shareItems.value = shareItems.value.map((item) => ({ ...item, status: STAGED }));
    }

    function finishSharingItems() {
        shareItems.value = shareItems.value.map((item) => ({ ...item, status: item.status === COMPLETE ? SUCCESS : item.status }));
    }

    async function shareItem(item) {
        try {
            updateShareItem({ ...item, status: LOADING });

            const { sharedResourceId } = await api.shareResource({
                ...item,
                tenantId: useAccountsStore().appId,
                sourceTenantId: item.sourceTenantId,
            });

            updateShareItem({ ...item, sharedResourceId, status: COMPLETE });
        } catch (e) {
            if (e.response?.status === 400 && e.response?.data?.error?.message) {
                const failureReasons = getFailureReasons(e.response.data.error.message);

                updateShareItem({ ...item, status: ERROR, failureReasons });
            } else {
                updateShareItem({ ...item, status: ERROR, failureReasons: [FAILURE_REASON.UNKNOWN_FAILURE] });
            }
        }
    }

    function shareValidItems() {
        const items = shareItems.value.filter(({ status }) => [ERROR, STAGED].includes(status));

        items.forEach((item) => {
            shareItem(item);
        });
    }

    async function fetchRequestErrors(payload) {
        try {
            const { failures } = await api.loadRequestErrors({ requestId: exportItem.value.requestId });

            updateExportItem({
                ...exportItem.value, ...payload, failureReasons: failures,
            });
        } catch (e) {
            updateExportItem({
                ...exportItem.value, ...payload,
            });
        }
    }

    async function handleExport(options = {}) {
        try {
            const { failureReasons, missingRequiredFeatures, warningReasons } = await api.validateExport(exportItem.value, options);

            exportItem.value.failureReasons = failureReasons;
            exportItem.value.missingRequiredFeatures = missingRequiredFeatures;
            exportItem.value.warningReasons = warningReasons;

            if (failureReasons?.length || missingRequiredFeatures?.length) {
                updateExportItem({ ...exportItem.value, status: REQUEST_STATUSES.FAILED, logEventStep: 'validate' });
            } else if (warningReasons?.length && !dismissedExportWarnings.value) {
                updateExportItem({ ...exportItem.value, status: REQUEST_STATUSES.IDLE, logEventStep: 'validate' });
            }
        } catch (e) {
            if (e.response?.status < 500 || e.response?.status >= 600) {
                updateExportItem({ ...exportItem.value, status: REQUEST_STATUSES.FAILED, logEventStep: 'validate' });

                return;
            }
        }
        const showWarnings = exportItem.value.warningReasons?.length && !dismissedExportWarnings.value;

        if (!exportItem.value.failureReasons?.length && !exportItem.value.missingRequiredFeatures?.length && !showWarnings) {
            try {
                const { requestId, status, sharedResource } = await api.createExportRequest(exportItem.value, options);
                const payload = {
                    ...exportItem.value, requestId, status, resourceType: sharedResource?.resourceType,
                };

                if (status === REQUEST_STATUSES.FAILED) {
                    fetchRequestErrors({ ...payload, logEventStep: 'export' });
                } else {
                    updateExportItem(payload);
                }
            } catch (e) {
                updateExportItem({ ...exportItem.value, status: REQUEST_STATUSES.FAILED, logEventStep: 'export' });
            }
        }
    }

    async function fetchExportStatus() {
        if (!exportItem.value.requestId || exportItem.value.apiRequestStatus === REQUEST_STATUSES.IN_PROGRESS) {
            return;
        }

        try {
            updateExportItem({ ...exportItem.value, apiRequestStatus: REQUEST_STATUSES.IN_PROGRESS });

            const { status } = await api.exportRequestStatus({ requestId: exportItem.value.requestId });

            if (status === REQUEST_STATUSES.FAILED) {
                fetchRequestErrors({ status: REQUEST_STATUSES.FAILED, apiRequestStatus: undefined });
            } else {
                updateExportItem({
                    ...exportItem.value, status, apiRequestStatus: undefined,
                });
            }
        } catch (e) {
            updateExportItem({ ...exportItem.value, status: REQUEST_STATUSES.FAILED, apiRequestStatus: undefined });
        }
    }

    async function createBundle({ bundle, successStatus }) {
        const tenantId = useAccountsStore().appId;

        try {
            const { bundleId } = await api.createBundle({ ...bundle, tenantId });

            updateExportItem({
                title: bundle.title, description: bundle.description, sharedResourceId: bundleId, tenantId, status: successStatus || REQUEST_STATUSES.QUEUED,
            });
        } catch (e) {
            let failureReasons = [];

            if (e.response?.status === 400
                    && e.response.data?.error?.details.length
                    && e.response.data.error.details[0]?.violations?.length) {
                // TODO need a better way of parsing error message
                failureReasons = getFailureReasons(e.response.data.error.details[0].violations[0].type);
            }
            updateExportItem({
                ...bundle, tenantId, status: REQUEST_STATUSES.FAILED, failureReasons, apiRequestUpdateTime: new Date(), logEventStep: 'create bundle',
            });
        }
    }

    async function refreshBundle() {
        const { sharedResourceId } = exportItem.value;

        try {
            await api.refreshBundle(sharedResourceId);
            updateExportItem({
                ...exportItem.value, status: REQUEST_STATUSES.COMPLETED,
            });
        } catch (e) {
            let failureReasons = [];

            if (e.response?.status === 400 && e.response?.data?.error?.message) {
                // TODO need a better way of parsing error message
                failureReasons = getFailureReasons(e.response.data.error.message);
            }
            updateExportItem({
                ...exportItem.value, status: REQUEST_STATUSES.FAILED, failureReasons, logEventStep: 'refresh',
            });
        }
    }

    async function fetchBundle(bundleId) {
        const bundle = await api.getBundle(bundleId);

        reset();
        bundle?.dependencies?.forEach((item) => {
            addShareItem({
                ...item,
                status: SUCCESS,
            });
        });

        updateExportItem({
            ...bundle,
            sharedResourceId: bundleId,
            resourceType: RESOURCE_TYPES.BUNDLE,
            status: REQUEST_STATUSES.COMPLETED,
        });
    }

    async function fetchSharedResource(sharedResourceId) {
        const item = await api.getSharedResource(sharedResourceId);

        reset();
        addShareItem({ ...item, status: SUCCESS });
        updateExportItem({ ...item, status: REQUEST_STATUSES.COMPLETED });
    }

    async function fetchFromPublicLink(linkId) {
        const promises = [linksApi.getPublicLinkInfo(linkId), linksApi.getPublicLinkDependencies(linkId)];
        const [linkInfo, { dependencies }] = await Promise.all(promises);

        shareItems.value = [];

        if (linkInfo?.resourceType === RESOURCE_TYPES.BUNDLE && dependencies.length) {
            dependencies?.forEach((item) => {
                addShareItem({
                    ...item,
                    status: SUCCESS,
                });
            });
        } else {
            addShareItem({
                title: linkInfo.title,
                description: linkInfo.description,
                resourceType: linkInfo.resourceType,
                status: SUCCESS,
            });
        }

        setPartnerInfo(linkInfo.company);
        updateExportItem({
            ...linkInfo,
            linkId,
            company: undefined,
            dependencies: undefined,
            status: REQUEST_STATUSES.COMPLETED,
        });
    }

    async function updateBundleInfo(bundleInfo) {
        const { title, description, topLevel } = await api.updateBundle(bundleInfo);

        updateExportItem({
            ...exportItem.value,
            title,
            description,
            topLevel,
        });
    }

    return {
        shareItems,
        exportItem,
        sourceSandboxId,
        partnerInfo,

        isSharingLoading,
        isSharingComplete,
        isSharingFailed,
        isSharingFinished,
        sharedItems,
        isExportLoading,
        isExportFailed,
        hasExportWarnings,
        sourceSandbox,
        dismissedExportWarnings,

        reset,
        resetExportItemStatus,
        shareItem,
        addShareItem,
        removeShareItem,
        removeShareItemByResourceId,
        updateShareItem,
        updateExportItem,
        setPartnerInfo,
        markShareItemsAsShared,
        markShareItemsAsStaged,
        finishSharingItems,
        shareValidItems,
        handleExport,
        fetchExportStatus,
        createBundle,
        refreshBundle,
        fetchBundle,
        fetchSharedResource,
        fetchFromPublicLink,
        fetchRequestErrors,
        updateBundleInfo,
        setSourceSandboxId,
        dismissExportWarnings,
        resetDismissExportWarnings,
    };
}, {
    persist: {
        paths: ['sourceSandboxId'],
    },
});
