<script>
import { onMounted } from 'vue';
import { usePendo } from '@keap-web/analytics-lib/pendo';
import { useFullStory } from '@keap-web/analytics-lib/fullstory';
import { useSentry } from '@keap-web/analytics-lib/sentry';
import { useAuthStore } from '@/stores/auth';
import { useAccountsStore } from '@/accounts/store';

export default {
    setup() {
        const authStore = useAuthStore();
        const accountsStore = useAccountsStore();
        const pendo = usePendo();
        const fullstory = useFullStory();
        const sentry = useSentry();

        onMounted(() => {
            const { user, jwt } = authStore;
            const { appId } = accountsStore;

            const casId = user?.id;
            const email = user?.email;
            const firstName = user?.firstName;
            const lastName = user?.lastName;
            const appName = appId;

            sentry.setUser({ casId });

            pendo.initialize({
                casId,
                email,
                firstName,
                lastName,
                appName,
                authToken: jwt,
            });

            fullstory.identify(casId, {
                email,
                appName,
                firstName,
                lastName,
                environment: window.__KEAP__.VUE_APP_ENV,
            });
        });
    },

    render() {
        return null;
    },
};
</script>
